import gql from "graphql-tag";

export const ADD_VOTE = gql`
  mutation AddVote($cardId: Int!, $userUid: String!) {
    insert_votes(objects: [{ card_id: $cardId, user_uid: $userUid }]) {
      returning {
        id
      }
    }
  }
`;
