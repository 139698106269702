import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCdEpxxcUmrX54pQIkZiZt31pL5Oazue3k",
  authDomain: "dotvoter-dev-stg.firebaseapp.com",
  projectId: "dotvoter-dev-stg",
  storageBucket: "dotvoter-dev-stg.appspot.com",
  messagingSenderId: "1035356232000",
  appId: "1:1035356232000:web:bd01d5704d109b0df382a5",
};

initializeApp(firebaseConfig);

export default function () {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          localStorage.setItem("user", JSON.stringify(firebaseUser));
        } else {
          localStorage.removeItem("user");
        }

        setUser(firebaseUser);
      });

    return () => unregisterAuthObserver();
  }, []);

  return user;
}
