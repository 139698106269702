import React, { useState } from "react";
import { ADD_BOARD } from "../../mutations/addBoard";
import { useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import GuestAlert from "../organisms/GuestAlert";
const uuidv4 = require("uuid/v4");

interface IProps {
  history: any;
}

const HomeView = (props: IProps) => {
  const [newBoardName, setNewBoardName] = useState("");
  const [newBoardDescription, setNewBoardDescription] = useState("");
  const [addBoard, response] = useMutation(ADD_BOARD);
  const user = useAuth();

  if (response.called && response.data) {
    const slug = response.data.insert_boards.returning[0].slug;
    props.history.push(`/boards/${slug}`);
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <GuestAlert text="to create a board" />
      <h2>create a board</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addBoard({
            variables: {
              name: newBoardName,
              description: newBoardDescription,
              userUid: user.uid,
              slug: uuidv4(),
            },
          });
        }}
      >
        <label className="form-label">
          name <small>(required)</small>
        </label>
        <input
          disabled={!user}
          className="form-control"
          value={newBoardName}
          required={true}
          onChange={(e) => setNewBoardName(e.target.value)}
        />
        <br />
        <label className="form-label">description</label>
        <input
          disabled={!user}
          className="form-control"
          value={newBoardDescription}
          onChange={(e) => setNewBoardDescription(e.target.value)}
        />
        <br />
        <div className="d-flex justify-content-center">
          <button disabled={!user} className="btn btn-primary" type="submit">
            create new board
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(HomeView);
