import React from "react";

interface IProps {
  isVotingPrivate: boolean;
  isIdeationPrivate: boolean;
  isLocked: boolean;
  allowedVotes: number;
  maxVotesPerPersonPerCard: number;
  onallowedVotesChange: (allowedVotes: number) => void;
  onMaxVotesPerPersonPerCardChange: (maxVotesPerPersonPerCard: number) => void;
  onIsVotingPrivateChange: (isVotingPrivate: boolean) => void;
  onIsIdeationPrivateChange: (isIdeationPrivate: boolean) => void;
  onLockSortVotes: () => void;
}

const BoardOptions = (props: IProps) => {
  const {
    isVotingPrivate,
    isIdeationPrivate,
    allowedVotes,
    onallowedVotesChange,
    onIsVotingPrivateChange,
    onIsIdeationPrivateChange,
    onLockSortVotes,
    isLocked,
    maxVotesPerPersonPerCard,
    onMaxVotesPerPersonPerCardChange,
  } = props;

  return (
    <div>
      <div className="card mb-4">
        <div className="card-body">
          <h5 className="card-title">board admin controls</h5>
          <label className="form-label">allowed votes per person</label>
          <div className="d-flex justify-content-start align-items-center">
            <button
              disabled={allowedVotes <= 1}
              type="button"
              onClick={() => {
                const numNewVotes = allowedVotes - 1;

                if (numNewVotes > 0) {
                  onallowedVotesChange(numNewVotes);
                }
              }}
              className="btn btn-secondary btn-sm px-2"
            >
              -
            </button>
            <div className="mx-3">{allowedVotes}</div>
            <button
              type="button"
              onClick={() => onallowedVotesChange(allowedVotes + 1)}
              className="btn btn-secondary btn-sm px-2"
            >
              +
            </button>
          </div>
          <br />
          <label className="form-label">max votes per person per card</label>
          <div className="d-flex justify-content-start align-items-center">
            <button
              disabled={maxVotesPerPersonPerCard <= 1}
              type="button"
              onClick={() => {
                const newMaxVotesPerPersonPerCard =
                  maxVotesPerPersonPerCard - 1;

                if (newMaxVotesPerPersonPerCard > 0) {
                  onMaxVotesPerPersonPerCardChange(newMaxVotesPerPersonPerCard);
                }
              }}
              className="btn btn-secondary btn-sm px-2"
            >
              -
            </button>
            <div className="mx-3">{maxVotesPerPersonPerCard}</div>
            <button
              type="button"
              onClick={() =>
                onMaxVotesPerPersonPerCardChange(maxVotesPerPersonPerCard + 1)
              }
              className="btn btn-secondary btn-sm px-2"
            >
              +
            </button>
          </div>
          <br />
          <div className="form-check">
            <label className="form-check-label">
              private ideation?
              <input
                checked={isIdeationPrivate}
                onChange={() => onIsIdeationPrivateChange(!isIdeationPrivate)}
                type="checkbox"
                className="form-check-input"
              />
            </label>
            <br />
            <label className="form-check-label">
              private voting?
              <input
                checked={isVotingPrivate}
                onChange={() => onIsVotingPrivateChange(!isVotingPrivate)}
                type="checkbox"
                className="form-check-input"
              />
            </label>
          </div>
          <br />
          <button className="btn btn-warning" onClick={onLockSortVotes}>
            {isLocked ? "Unlock board" : "lock & sort by votes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoardOptions;
