import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_VOTE } from "../../mutations/addVote";
import { DELETE_VOTE } from "../../mutations/deleteVote";
import { DELETE_CARD } from "../../mutations/deleteCard";
import useAuth from "../../hooks/useAuth";

interface ICard {
  text: string;
  votes: IVote[];
  cardId: number;
  canVote: boolean;
  isOwnCard: boolean;
  isLocked: boolean;
  isBoardOwner: boolean;
}

interface IVote {
  id: number;
  user_uid: string;
}

const Card = (props: ICard) => {
  const user = useAuth();
  // TODO: move this to persistent object attributes in db
  // const colors = ["#ff7eb9", "#ff65a3", "#7afcff", "#feff9c", "#fff740"];
  const color = "#feff9c"; // colors[props.cardId % colors.length];

  const { canVote, isOwnCard, isLocked } = props;

  // @ts-ignore
  const [addVote] = useMutation(ADD_VOTE);
  const [deleteVote] = useMutation(DELETE_VOTE);
  const [deleteCard] = useMutation(DELETE_CARD);

  const canDelete = !isLocked && (isOwnCard || props.isBoardOwner);

  const onClick = () => {
    if (canVote) {
      addVote({
        variables: {
          cardId: props.cardId,
          userUid: user.uid,
        },
      });
    }
  };

  const onVoteClick = (voteId: any) => {
    deleteVote({
      variables: {
        voteId,
      },
    });
  };

  const onClickDelete = (event: any) => {
    event.stopPropagation();
    if (canDelete) {
      deleteCard({ variables: { cardId: props.cardId } });
    }
  };

  return (
    <div>
      <div
        style={{
          height: "100%",
          width: "100%",
          minHeight: "12em",
          backgroundColor: color,
          borderRadius: 6,
          cursor: canVote ? "pointer" : "not-allowed",
          wordWrap: "break-word",
        }}
        onClick={onClick}
        className="shadow-sm p-2 d-flex flex-column justify-content-between"
      >
        <p>{props.text}</p>
        <div>
          {props.votes.map((vote: IVote) => {
            const isOwnVote = user && vote.user_uid === user.uid;
            const canDelete = !isLocked && isOwnVote;

            return (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  if (canDelete) {
                    onVoteClick(vote.id);
                  }
                }}
                key={vote.id}
                style={{
                  display: "inline-block",
                  marginRight: "4px",
                  height: "25px",
                  width: "25px",
                  backgroundColor: isOwnVote ? "#333333" : "#777777",
                  borderRadius: "50%",
                  cursor: canDelete ? "pointer" : "not-allowed",
                }}
              />
            );
          })}
        </div>
      </div>
      {canDelete ? (
        <button
          style={{ width: "100%" }}
          onClick={onClickDelete}
          className="btn btn-danger btn-sm p-0 px-0"
        >
          delete
        </button>
      ) : null}
    </div>
  );
};

export default Card;
