import { gql } from "apollo-boost";

export const GET_BOARD_USERS = gql`
  query($userUid: String!) {
    board_users(where: { user_uid: { _eq: $userUid } }) {
      id
      board {
        name
        id
        description
        slug
        created_at
        user_uid
      }
    }
  }
`;
