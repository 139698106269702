import gql from "graphql-tag";

export const DELETE_VOTE = gql`
  mutation DeleteVote($voteId: Int!) {
    delete_votes(where: {id: {_eq: $voteId}}) {
      returning {
        id
      }
    }
  }
`;
