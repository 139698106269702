import { gql } from "apollo-boost";

export const GET_BOARD = gql`
  subscription ($slug: String!) {
    boards(where: { slug: { _eq: $slug } }) {
      name
      user_uid
      is_voting_private
      is_ideation_private
      allowed_votes
      id
      is_locked
      description
      max_votes_per_person_per_card
      cards {
        id
        text
        user_uid
        votes {
          id
          user_uid
        }
      }
      board_users {
        id
        user_uid
        user_name
        last_seen
      }
    }
  }
`;
