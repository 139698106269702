import gql from "graphql-tag";

export const DELETE_CARD = gql`
  mutation DeleteCard($cardId: Int!) {
    delete_cards(where: {id: {_eq: $cardId}}) {
      returning {
        id
      }
    }
  }
`;
