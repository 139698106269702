import React, { useEffect, useState } from "react";
import { GET_BOARD } from "../../queries/board";
import { UPDATE_BOARD } from "../../mutations/updateBoard";
import { ADD_CARD } from "../../mutations/addCard";
import { ADD_BOARD_USER } from "../../mutations/addBoardUser";
import { UPDATE_BOARD_USER } from "../../mutations/updateBoardUser";
import LoadingSpinner from "../organisms/LoadingSpinner";
import { useParams } from "react-router-dom";
import { useSubscription, useMutation } from "@apollo/react-hooks";
import Card from "../organisms/Card";
import Flex from "../layouts/Flex";
import BoardOptions from "../organisms/BoardOptions";
import { chunk } from "lodash";
import useAuth from "../../hooks/useAuth";
import GuestAlert from "../organisms/GuestAlert";
import { sub, isAfter } from "date-fns";

const BoardView: React.FC = () => {
  const user = useAuth();
  const { slug } = useParams();
  const { loading, error, data } = useSubscription(GET_BOARD, {
    variables: { slug },
  });
  const [newCardText, setNewCardText] = useState("");
  const [addCard] = useMutation(ADD_CARD);
  const [updateBoard] = useMutation(UPDATE_BOARD);
  const [addBoardUser] = useMutation(ADD_BOARD_USER);
  const [updateBoardUser] = useMutation(UPDATE_BOARD_USER);

  useEffect(() => {
    if (user && data && data.boards && data.boards[0]) {
      const board = data.boards[0];
      // @ts-ignore
      const boardUserIds = board.board_users.map((bu) => bu.user_uid);

      if (!boardUserIds.includes(user.uid)) {
        addBoardUser({
          variables: {
            userUid: user.uid,
            boardId: board.id,
            userName: user.displayName,
            lastSeen: new Date().toISOString(),
          },
        });
      }
    }
  }, [data, user, addBoardUser]);

  useEffect(() => {
    if (user && data && data.boards && data.boards[0]) {
      const interval = setInterval(() => {
        const boardUser = data.boards[0].board_users.find(
          (boardUser: any) => boardUser.user_uid === user.uid
        );
        updateBoardUser({
          variables: {
            boardUserId: boardUser.id,
            lastSeen: new Date().toISOString(),
          },
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [data, user, updateBoardUser]);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>`Error! ${error.message}`</p>;

  const board = data.boards[0];

  const {
    id,
    name,
    description,
    user_uid,
    is_ideation_private,
    allowed_votes,
    is_voting_private,
    is_locked,
    board_users,
    max_votes_per_person_per_card,
  } = board;

  let { cards } = board;
  const isBoardOwner = user && user_uid === user.uid;

  // @ts-ignore
  const ownVotes = cards
    .map((card: any) => card.votes)
    .flat()
    .filter((vote: any) => user && vote.user_uid === user.uid);
  let numRemainingVotes = allowed_votes - ownVotes.length;
  const canVote = numRemainingVotes > 0;

  numRemainingVotes = canVote ? numRemainingVotes : 0;

  const onallowedVotesChange = (allowedVotes: number) => {
    updateBoard({
      variables: {
        boardId: id,
        allowedVotes,
        isIdeationPrivate: is_ideation_private,
        isVotingPrivate: is_voting_private,
        isLocked: is_locked,
        maxVotesPerPersonPerCard: max_votes_per_person_per_card,
      },
    });
  };

  const onMaxVotesPerPersonPerCardChange = (
    maxVotesPerPersonPerCard: number
  ) => {
    updateBoard({
      variables: {
        boardId: id,
        allowedVotes: allowed_votes,
        isIdeationPrivate: is_ideation_private,
        isVotingPrivate: is_voting_private,
        isLocked: is_locked,
        maxVotesPerPersonPerCard,
      },
    });
  };

  const onIsIdeationPrivateChange = (isIdeationPrivate: boolean) => {
    updateBoard({
      variables: {
        boardId: id,
        allowedVotes: allowed_votes,
        isIdeationPrivate: isIdeationPrivate,
        isVotingPrivate: is_voting_private,
        isLocked: is_locked,
        maxVotesPerPersonPerCard: max_votes_per_person_per_card,
      },
    });
  };

  const onIsVotingPrivateChange = (isVotingPrivate: boolean) => {
    updateBoard({
      variables: {
        boardId: id,
        allowedVotes: allowed_votes,
        isIdeationPrivate: is_ideation_private,
        isVotingPrivate: isVotingPrivate,
        isLocked: is_locked,
        maxVotesPerPersonPerCard: max_votes_per_person_per_card,
      },
    });
  };

  const onLockSortVotes = () => {
    const nextIsLocked = !is_locked;

    updateBoard({
      variables: {
        boardId: id,
        allowedVotes: allowed_votes,
        isIdeationPrivate: nextIsLocked ? false : is_ideation_private,
        isVotingPrivate: nextIsLocked ? false : is_voting_private,
        isLocked: nextIsLocked,
        maxVotesPerPersonPerCard: max_votes_per_person_per_card,
      },
    });
  };

  cards = cards
    .filter((card: any) =>
      is_ideation_private ? user && card.user_uid === user.uid : true
    )
    .sort((carda: any, cardb: any) =>
      is_locked
        ? carda.votes.length > cardb.votes.length
          ? -1
          : 1
        : carda.created_at > cardb.created_at
        ? 1
        : -1
    )
    .map((card: any) => {
      const votes = is_voting_private
        ? card.votes.filter((card: any) => user && card.user_uid === user.uid)
        : card.votes;

      return { ...card, votes };
    });

  const allVotes = cards.map((card: any) => card.votes).flat();

  const boardUsers = board_users
    .filter((board_user: any) => {
      const lastSeen = new Date(board_user.last_seen);
      const threshold = sub(new Date(), { seconds: 10 });
      return isAfter(lastSeen, threshold);
    })
    .sort((a: any, b: any) => {
      return user && a.user_name > b.user_name ? 1 : -1;
    })
    .sort((a: any, b: any) => {
      return user && a.user_uid === user.uid ? -1 : 1;
    })
    .map((board_user: any) => {
      const votes = allVotes.filter(
        (vote: any) => vote.user_uid === board_user.user_uid
      );

      return {
        ...board_user,
        numVotes: votes.length,
      };
    });

  return (
    <Flex m={3} flexDirection="column">
      <h2>{name}</h2>
      <h4>{description}</h4>
      <GuestAlert text="to add cards or vote" />
      {isBoardOwner ? (
        <BoardOptions
          onallowedVotesChange={onallowedVotesChange}
          onIsVotingPrivateChange={onIsVotingPrivateChange}
          onIsIdeationPrivateChange={onIsIdeationPrivateChange}
          isIdeationPrivate={is_ideation_private}
          isVotingPrivate={is_voting_private}
          allowedVotes={allowed_votes}
          onLockSortVotes={onLockSortVotes}
          isLocked={is_locked}
          maxVotesPerPersonPerCard={max_votes_per_person_per_card}
          onMaxVotesPerPersonPerCardChange={onMaxVotesPerPersonPerCardChange}
        />
      ) : null}
      <div className="accordion mb-4" id="user-list-accordian">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#user-list"
              aria-expanded="false"
              aria-controls="user-list"
            >
              active users ({boardUsers.length})
            </button>
          </h2>
          <div
            id="user-list"
            className="accordion-collapse collapse"
            aria-labelledby="user-list"
            data-bs-parent="#user-list-accordian"
          >
            <ul className="list-group">
              {/* @ts-ignore */}
              {boardUsers.map((boardUser: any) => (
                <li key={boardUser.id} className="list-group-item">
                  {user && boardUser.user_uid === user.uid ? (
                    <strong>{boardUser.user_name}</strong>
                  ) : (
                    boardUser.user_name
                  )}{" "}
                  {`${boardUser.numVotes}/${allowed_votes} votes${
                    boardUser.numVotes === allowed_votes ? " ✅" : ""
                  }`}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {is_locked ? null : (
        <div className="mb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addCard({
                variables: {
                  text: newCardText,
                  boardId: data.boards[0].id,
                  userUid: user.uid,
                },
              });
              setNewCardText("");
            }}
          >
            <input
              disabled={!user}
              className="form-control"
              value={newCardText}
              onChange={(e) => setNewCardText(e.target.value)}
            />
            <button
              disabled={!newCardText || !user}
              type="submit"
              className="btn btn-primary mt-1"
            >
              add card
            </button>
          </form>
        </div>
      )}

      {is_locked ? null : (
        <div>
          <span>
            you have <strong>{numRemainingVotes}</strong> votes left
          </span>
          <br />
          <small>
            max <strong>{max_votes_per_person_per_card}</strong> vote
            {max_votes_per_person_per_card === 1 ? "" : "s"} per person per card
          </small>
          <br />
          <small>click on a card to add a vote</small>
          <br />
          <small>click on one of your votes to remove it</small>
        </div>
      )}

      {cards.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p>start by adding some cards!</p>
          <img
            src={process.env.PUBLIC_URL + "/chickpea.png"}
            style={{ height: 400, width: 400 }}
            alt="chickpea"
          />
        </div>
      ) : (
        chunk(cards, 4).map((_cards: any) => (
          <div className="row" key={_cards[0].id}>
            {_cards.map((card: any) => {
              const numOwnCardVotes = card.votes.filter(
                (vote: any) => user && vote.user_uid === user.uid
              ).length;

              return (
                <div className="col-lg-3 my-4" key={card.id}>
                  <Card
                    canVote={
                      !user || is_locked
                        ? false
                        : numOwnCardVotes < max_votes_per_person_per_card &&
                          canVote
                    }
                    cardId={card.id}
                    text={card.text}
                    votes={card.votes}
                    isLocked={is_locked}
                    isOwnCard={user && card.user_uid === user.uid}
                    isBoardOwner={isBoardOwner}
                  ></Card>
                </div>
              );
            })}
          </div>
        ))
      )}
    </Flex>
  );
};

export default BoardView;
