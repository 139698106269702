import React from "react";
import useAuth from "../../hooks/useAuth";

interface IProps {
  text: string;
}

const GuestAlert = (props: IProps) => {
  const user = useAuth();

  return !user ? (
    <div className="alert alert-warning" role="alert">
      {`sign in or sign up ${props.text}`}
    </div>
  ) : null;
};

export default GuestAlert;
