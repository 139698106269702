import gql from "graphql-tag";

export const UPDATE_BOARD_USER = gql`
  mutation UpdateBoardUser(
    $boardUserId: Int!
    $lastSeen: timestamptz
  ) {
    update_board_users(
      where: { id: { _eq: $boardUserId } }
      _set: {
        last_seen: $lastSeen
      }
    ) {
      returning {
        id
      }
    }
  }
`;
