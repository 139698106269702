import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import Router from "./Router";
import { ThemeProvider } from "styled-components";
import theme from "./config/theme";
import { client } from "./apollo";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
  apiKey: "AIzaSyCdEpxxcUmrX54pQIkZiZt31pL5Oazue3k",
  authDomain: "dotvoter-dev-stg.firebaseapp.com",
  projectId: "dotvoter-dev-stg",
  storageBucket: "dotvoter-dev-stg.appspot.com",
  messagingSenderId: "1035356232000",
  appId: "1:1035356232000:web:bd01d5704d109b0df382a5",
};

firebase.initializeApp(config);

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
