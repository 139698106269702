import styled from "styled-components";
import { flexbox, FlexboxProps } from "styled-system";
import { space, SpaceProps } from "styled-system";

interface IFlex extends FlexboxProps, SpaceProps {}

const Flex = styled.div<IFlex>`
  display: flex;
  ${flexbox};
  ${space}
`;

export default Flex;
