import gql from "graphql-tag";

export const UPDATE_BOARD = gql`
  mutation UpdateBoard(
    $boardId: Int!
    $isIdeationPrivate: Boolean
    $isVotingPrivate: Boolean
    $allowedVotes: Int
    $maxVotesPerPersonPerCard: Int
    $isLocked: Boolean
  ) {
    update_boards(
      where: { id: { _eq: $boardId } }
      _set: {
        is_ideation_private: $isIdeationPrivate
        is_voting_private: $isVotingPrivate
        allowed_votes: $allowedVotes
        is_locked: $isLocked
        max_votes_per_person_per_card: $maxVotesPerPersonPerCard
      }
    ) {
      returning {
        id
      }
    }
  }
`;
