import gql from "graphql-tag";

export const ADD_BOARD = gql`
  mutation AddBoard(
    $name: String!
    $description: String
    $userUid: String!
    $slug: String!
  ) {
    insert_boards(
      objects: [
        {
          name: $name
          description: $description
          user_uid: $userUid
          slug: $slug
        }
      ]
    ) {
      returning {
        id
        slug
      }
    }
  }
`;
