import gql from "graphql-tag";

export const ADD_CARD = gql`
  mutation AddCard($text: String!, $boardId: Int!, $userUid: String!) {
    insert_cards(objects: [{ text: $text, board_id: $boardId, user_uid: $userUid }]) {
      returning {
        id
      }
    }
  }
`;
