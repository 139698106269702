import gql from "graphql-tag";

export const ADD_BOARD_USER = gql`
  mutation AddBoardUser($lastSeen: timestamptz!, $userUid: String!, $boardId: Int!, $userName: String!) {
    insert_board_users(objects: [{ last_seen: $lastSeen, user_uid: $userUid, board_id: $boardId, user_name: $userName }]) {
      returning {
        id
      }
    }
  }
`;
