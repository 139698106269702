import React from "react";
import { withRouter } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../organisms/LoadingSpinner";
import { GET_BOARD_USERS } from "../../queries/board_users";
import { useQuery } from "@apollo/react-hooks";
import { format } from "date-fns";

interface IProps {
  history: any;
}

const HomeViewWrapper = (props: IProps) => {
  const user = useAuth();

  if (user) {
    return <HomeView user={user} />;
  } else {
    return <LoadingSpinner />;
  }
};

interface IHomeViewProps {
  user: any;
}

const HomeView = (props: IHomeViewProps) => {
  const { user } = props;
  const { loading, error, data } = useQuery(GET_BOARD_USERS, {
    variables: { userUid: props.user.uid },
  });

  if (loading) return <LoadingSpinner />;
  if (error) return <p>`Error! ${error.message}`</p>;

  const boards = [
    // @ts-ignore
    ...new Set(data.board_users.map((board_user) => board_user.board)),
  ].sort((a: any, b: any) => (a.created_at > b.created_at ? -1 : 1));

  return (
    <div>
      <h2>prior boards</h2>
      <br />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">creator?</th>
            <th scope="col">name</th>
            <th scope="col">description</th>
            <th scope="col">created</th>
          </tr>
        </thead>
        <tbody>
          {boards.map((board: any) => {
            const createdAt = format(new Date(board.created_at), "Pp");
            return (
              <tr key={board.id}>
                <th scope="row">{board.user_uid === user.uid ? "⭐️" : ""}</th>
                <td>
                  <a href={`/boards/${board.slug}`}>{board.name}</a>
                </td>
                <td>{board.description ? board.description : "--"}</td>
                <td>{`${createdAt}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(HomeViewWrapper);
