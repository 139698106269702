import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import BoardView from "./components/views/BoardView";
import BoardsView from "./components/views/BoardsView";
import HomeView from "./components/views/HomeView";
import AuthView from "./components/views/AuthView";
import useAuth from "./hooks/useAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const Router = () => {
  const user = useAuth();
  const onClick = () => {
    const w = 500;
    const h = 600;
    var left = window.screen.width / 2 - w / 2;
    var top = window.screen.height / 2 - h / 2;

    return window.open(
      "/auth",
      "sign in to dotvoter",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  return (
    <BrowserRouter>
      {/* <div className="container"> */}
      <Switch>
        <Route exact path="/auth">
          <AuthView />
        </Route>
        <div>
          <nav className="navbar navbar-light bg-light px-3">
            <a className="navbar-brand" href="/">
              <span role="img" aria-label="baby">
                👶
              </span>{" "}
              dotvoter
            </a>
            {user ? (
              <div>
                <span>
                  <strong>{user.displayName}</strong>
                </span>
                <a className="mx-2" href="/">
                  new board
                </a>
                <a className="mx-2" href="/boards">
                  prior boards
                </a>
                <span className="mx-2">|</span>
                {/* eslint-disable-next-line */}
                <a
                  href="#"
                  className="mx-2"
                  onClick={() => firebase.auth().signOut()}
                >
                  logout
                </a>
              </div>
            ) : (
              // eslint-disable-next-line
              <a href="#" onClick={onClick}>
                sign in/sign up
              </a>
            )}
          </nav>
          <div className="container py-3">
            <Route exact path="/">
              <HomeView />
            </Route>
            <Route exact path="/boards/:slug">
              <BoardView />
            </Route>
            <Route exact path="/boards">
              <BoardsView />
            </Route>
          </div>
        </div>
      </Switch>
      {/* </div> */}
    </BrowserRouter>
  );
};

export default Router;
