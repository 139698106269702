import React from "react";

const LoadingSpinner = () => (
  <div className="d-flex justify-content-center" style={{ marginTop: "100px" }}>
    <div
      className="spinner-border text-warning"
      style={{ width: "100px", height: "100px" }}
      role="status"
    ></div>
  </div>
);

export default LoadingSpinner;
